import { Component, NgZone } from '@angular/core';
import { sharedState } from '@citizens/mfe-shared-state';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';

@Component({
  selector: 'app-footer-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showFooter: boolean = false;

  constructor(
    private ngZone: NgZone
  ) {
  }

  ngOnInit() {
    console.log("footer MFE location", window.location)
    sharedState.getFlagBS('showFooter').subscribe((response) => {
      this.ngZone.run(()=> {
        this.showFooter = (response === true || response === 'true');
      })
    })
  }
}
