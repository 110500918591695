import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiCallServiceService } from './services/api-call-service.service';

@NgModule({
  declarations: [
    AppComponent,
    DynamicComp,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
   ],
  providers: [ApiCallServiceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
