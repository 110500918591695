<footer class="footer" *ngIf="!butlerApp">
  <hr>
  <div class="footer-top-section" *ngIf="isLoginRoute">
    <div class="container footer-content">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="footer-logo"></div>
        </div>

        <div class="footer-company col-md-3 col-6">
          <span class="footer-heading">Company</span>
          <ul class="footer-top-links" *ngFor="let item of footerCompanyLinks">
            <li [tabIndex]="item" role="listitem" (click)="selectCompanyLink(item.url)"
              (touchend)="selectCompanyLink(item.url)">{{item.name}}</li>
          </ul>
        </div>
        <div class="footer-resources col-md-3 col-6">
          <span class="footer-heading">Resources</span>
          <ul class="footer-top-links" *ngFor="let item of footerResourcesLinks">
            <li [tabIndex]="item" role="listitem" (click)="selectCompanyLink(item.url)"
              (touchend)="selectCompanyLink(item.url)">{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom-section" [ngClass]="{'margin-check': !isLoginRoute}">
    <div class="container footer-bottom">
      <div class="row">
        <div class="col-md-12 col-12 footer-area">
          <span class="footerItem" *ngFor="let item of footerBottomLinks">
            <a rel="noopener noreferrer nofollow" target="_blank" href="{{ item.url }}">{{ item.name }}</a>
          </span>
          <div id="disclaimer" class="disclaimer" *ngIf="isSMBUser">
            {{ disclaimer }}
          </div>

          <!-- footer mobile view -->
          <div class="footer-items">
            <div class="top-row">
              <span *ngFor="let item of footerBottomFirstLinks; let i = index">
                <a [href]="item.url">{{ item.name }}</a>
                <span *ngIf="i < footerBottomFirstLinks.length - 1"> | </span>
              </span>
            </div>
            <div class="bottom-row">
              <span *ngFor="let item of footerBottomSecondLinks; let i = index">
                <a [href]="item.url">{{ item.name }}</a>
                <span *ngIf="i < footerBottomSecondLinks.length - 1"> | </span>
              </span>
            </div>
            <div id="disclaimer" class="disclaimer" *ngIf="isSMBUser">
              {{ disclaimer }}
            </div>
            <!-- footer mobile view -->
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container footer-content" [ngClass]="{ 'fixedMargin': isDashboard }"
      [ngStyle]="{'text-align': textAlign}">
      <span class="footerItem" *ngFor="let item of footer | keyvalue" [ngSwitch]="item.value.type">
        <a *ngSwitchCase="'link'" rel="noopener noreferrer nofollow" target="_blank" href="{{ item.value.href }}"
          [ngStyle]="item.value.style">{{ item.value.text }}</a>
        <img *ngSwitchCase="'image'" width="{{ item.value.width }}" alt="{{ item.value.alt }}" src="{{ item.value.src }}"
          [ngStyle]="item.value.style" />
        <span *ngSwitchCase="'text'" [ngStyle]="item.value.style">{{ item.value.text }}</span>
      </span>
    </div>
    <div *ngIf="!butlerApp" id="copyright" class="container" [ngClass]="{ 'fixedMargin': isDashboard }"> &copy; {{ year }}
      {{ copyright }}
    </div>
    <div *ngIf="isSMBUser" id="disclaimer" class="container" [ngClass]="{ 'fixedMargin': isDashboard }">
      {{ disclaimer }}
    </div> -->
</footer>